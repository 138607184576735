import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 724.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,724.000000) scale(0.100000,-0.100000)">
<path d="M3656 5925 c-5 -27 -14 -75 -21 -105 -7 -30 -20 -91 -30 -135 -17
-78 -50 -195 -96 -343 -20 -66 -26 -75 -61 -93 -86 -44 -196 -185 -254 -326
-33 -82 -33 -83 31 -83 30 0 55 -2 55 -4 0 -3 -9 -21 -21 -40 -20 -34 -24 -36
-74 -36 l-52 0 -6 -42 c-13 -100 -27 -158 -43 -183 -12 -19 -13 -24 -3 -20 12
4 14 -21 15 -142 0 -148 15 -294 35 -352 l11 -31 209 0 209 0 0 385 0 385
-150 0 c-82 0 -150 3 -150 8 0 4 9 22 20 40 l20 32 130 0 130 0 -2 222 -3 223
-22 0 c-13 0 -23 2 -24 5 -1 3 12 50 29 105 45 147 98 358 118 470 7 37 10 28
40 -105 42 -189 83 -339 132 -477 21 -62 37 -113 34 -113 -3 0 -23 15 -46 34
-48 39 -111 72 -146 77 l-25 4 -3 -222 -2 -223 194 0 195 0 32 -57 c39 -70 80
-125 87 -119 2 3 -3 12 -12 21 -26 26 -93 147 -129 232 -18 43 -50 107 -71
142 -41 71 -70 142 -122 301 -39 117 -105 377 -124 485 -6 39 -15 84 -19 100
-8 28 -8 27 -15 -20z"/>
<path d="M4264 5895 c-11 -29 15 -42 93 -48 89 -7 218 -45 289 -84 61 -33 171
-117 203 -156 14 -16 30 -34 36 -41 23 -24 73 -104 102 -166 35 -72 63 -193
63 -268 0 -36 5 -54 20 -67 17 -15 22 -16 36 -4 14 11 16 26 10 86 -15 184
-99 371 -228 504 -66 68 -139 129 -156 129 -5 0 -15 6 -21 14 -19 23 -171 83
-256 100 -101 21 -184 21 -191 1z"/>
<path d="M4264 5636 c-9 -23 14 -46 47 -46 52 0 163 -31 221 -62 139 -74 242
-227 258 -381 7 -72 17 -96 42 -97 35 -1 30 114 -10 225 -33 93 -55 126 -142
216 -83 86 -212 145 -335 156 -64 5 -75 4 -81 -11z"/>
<path d="M4270 5395 c-20 -25 -4 -42 46 -50 126 -18 215 -105 231 -222 8 -69
27 -88 56 -59 32 33 -17 176 -84 244 -67 68 -220 121 -249 87z"/>
<path d="M3210 5204 c-104 -51 -256 -161 -310 -225 -24 -30 -48 -55 -52 -57
-5 -2 -8 -8 -8 -14 0 -5 -9 -23 -21 -39 l-20 -29 139 0 139 0 27 74 c33 90
103 214 157 278 22 26 37 48 32 48 -4 0 -42 -17 -83 -36z"/>
<path d="M3900 5238 c0 -2 14 -20 31 -39 43 -47 97 -131 126 -198 14 -31 31
-70 39 -88 8 -17 14 -38 14 -47 0 -25 14 -27 157 -24 l131 3 -31 46 c-51 74
-117 146 -161 176 -23 16 -43 30 -46 33 -13 15 -169 108 -195 117 -16 6 -38
14 -47 18 -10 4 -18 5 -18 3z"/>
<path d="M2750 4748 c0 -7 -6 -24 -13 -38 -24 -46 -47 -128 -58 -207 -10 -73
-7 -244 7 -319 6 -29 2 -34 -50 -69 -31 -20 -56 -39 -56 -42 0 -5 13 2 69 40
35 24 45 27 52 16 5 -8 9 -18 9 -24 0 -5 10 -33 22 -62 l22 -53 148 0 c121 0
148 3 148 14 0 8 -7 50 -15 93 -8 43 -18 135 -22 206 -5 114 -4 129 12 147 16
18 17 20 1 20 -18 0 -18 -7 10 175 8 50 14 96 14 103 0 9 -37 12 -150 12 -113
0 -150 -3 -150 -12z"/>
<path d="M3640 4375 l0 -385 209 0 209 0 11 33 c45 145 46 530 1 698 l-11 39
-209 0 -210 0 0 -385z"/>
<path d="M4151 4718 c6 -23 8 -47 5 -54 -2 -6 3 -31 12 -55 20 -55 23 -386 5
-464 -7 -27 -15 -74 -18 -102 l-7 -53 150 0 149 0 17 43 c10 23 28 80 41 127
42 153 22 427 -40 546 -8 16 -15 35 -15 42 0 9 -37 12 -154 12 l-154 0 9 -42z"/>
<path d="M3045 4490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4570 4194 c14 -16 164 -125 190 -138 19 -9 -6 10 -95 76 -38 29 -76
58 -83 65 -19 18 -27 16 -12 -3z"/>
<path d="M2502 4029 c-18 -12 -31 -24 -28 -26 2 -3 22 7 43 21 45 31 32 35
-15 5z"/>
<path d="M4780 4046 c0 -7 76 -56 87 -56 10 0 -7 14 -49 39 -21 13 -38 20 -38
17z"/>
<path d="M2436 3985 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14 0z"/>
<path d="M2384 3954 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M4917 3959 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2324 3924 c-18 -14 -18 -14 6 -3 31 14 36 19 24 19 -6 0 -19 -7 -30
-16z"/>
<path d="M4990 3920 c19 -11 42 -19 50 -19 8 0 -5 8 -29 19 -55 25 -67 25 -21
0z"/>
<path d="M2275 3900 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2805 3900 c-3 -5 -3 -10 2 -10 4 0 14 -12 20 -27 7 -16 24 -41 38
-57 42 -49 51 -45 -155 -71 -121 -16 -204 -31 -500 -95 l-85 -18 -85 24 c-111
32 -203 64 -215 75 -5 5 -20 9 -33 9 -20 0 -106 29 -177 60 -172 75 -189 81
-211 76 -25 -7 -36 -28 -27 -52 5 -15 152 -88 201 -101 28 -7 24 -25 -9 -34
-23 -7 -23 -7 -2 -8 12 -1 33 3 47 9 19 7 39 3 87 -16 35 -13 90 -32 124 -41
33 -9 78 -24 100 -33 l40 -17 -80 -26 c-44 -15 -92 -27 -108 -27 -15 0 -27 -4
-27 -8 0 -4 -17 -14 -37 -21 -71 -24 -198 -76 -208 -85 -5 -4 -35 -20 -65 -35
-44 -21 -56 -31 -58 -53 -3 -21 2 -30 20 -39 25 -11 30 -9 177 58 19 9 70 28
113 43 42 15 75 30 72 33 -4 3 7 9 22 12 16 4 54 15 84 24 48 16 61 16 100 5
51 -15 66 -12 21 4 -17 6 -31 13 -31 16 0 2 26 12 58 21 31 9 69 21 83 27 19
7 47 4 105 -11 43 -12 158 -37 254 -57 96 -20 177 -39 180 -44 3 -4 104 -19
225 -33 419 -51 863 -57 1220 -17 214 23 365 45 365 52 0 4 17 10 38 13 33 5
170 32 287 55 22 5 53 13 68 19 49 19 108 12 237 -28 120 -37 148 -54 119 -72
-8 -5 1 -6 24 -2 41 6 86 -8 289 -94 110 -46 143 -50 161 -22 18 29 -1 49 -80
85 -43 20 -80 39 -83 43 -5 6 -60 31 -105 46 l-20 7 20 9 c15 7 13 8 -10 4
-27 -5 -232 55 -280 82 -20 11 -11 16 125 58 47 15 99 33 117 41 17 8 35 14
40 14 5 0 38 12 72 26 l63 25 88 -20 c48 -12 94 -21 101 -20 8 1 -26 11 -76
23 l-90 22 60 30 c66 32 83 63 48 89 -22 17 -61 6 -182 -53 -36 -18 -100 -22
-141 -9 -22 7 -21 6 4 -9 l29 -16 -33 -14 c-67 -29 -235 -84 -299 -100 -36 -8
-77 -20 -92 -25 -18 -7 -42 -5 -85 6 -32 9 -79 20 -104 25 -25 5 -76 16 -115
25 -107 24 -287 55 -317 55 -15 0 -30 4 -33 10 -3 5 19 42 49 82 30 40 52 76
50 80 -6 10 -245 10 -260 0 -6 -4 -22 -32 -34 -62 -13 -30 -27 -60 -31 -66 -9
-11 -79 -6 -79 6 0 5 9 29 20 55 34 80 44 76 -172 73 l-193 -3 -3 -57 -3 -58
-39 0 -39 0 -3 58 -3 57 -192 3 c-214 3 -207 5 -178 -65 23 -55 20 -63 -24
-63 -39 0 -40 1 -57 53 -9 28 -21 57 -26 63 -12 16 -264 19 -273 4z m835 -320
c0 -133 -2 -130 69 -105 51 18 122 71 162 122 17 21 34 40 38 43 4 3 10 13 14
23 5 12 17 17 45 17 21 0 41 -3 45 -6 8 -9 -48 -97 -85 -133 -18 -17 -27 -31
-20 -31 23 0 121 49 199 99 l79 51 65 -10 c146 -23 256 -42 294 -50 22 -5 66
-14 97 -20 32 -6 60 -15 63 -19 4 -8 -201 -49 -350 -71 -338 -49 -738 -69
-1052 -52 -230 13 -276 17 -523 48 -202 24 -456 76 -430 88 53 22 518 96 606
96 39 0 57 -7 108 -44 63 -44 202 -116 227 -116 8 0 2 11 -15 26 -31 29 -96
127 -96 144 0 19 82 7 94 -14 6 -10 20 -28 31 -40 11 -11 27 -30 35 -41 25
-34 102 -91 148 -108 74 -29 72 -31 72 103 l0 120 40 0 40 0 0 -120z"/>
<path d="M2225 3880 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M5070 3880 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M5120 3863 c0 -7 93 -44 103 -40 6 2 -15 12 -46 24 -32 11 -57 19
-57 16z"/>
<path d="M2160 3850 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M2105 3830 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M2025 3800 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M5270 3800 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M1965 3780 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M1885 3755 c-22 -7 -31 -13 -20 -14 11 0 34 6 50 14 38 17 22 17 -30
0z"/>
<path d="M5753 3673 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1493 3663 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5808 3663 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1365 3650 l-50 -7 50 0 c28 0 64 3 80 7 l30 8 -30 -1 c-16 0 -52 -3
-80 -7z"/>
<path d="M5890 3650 c25 -8 117 -14 109 -6 -2 2 -33 6 -69 9 -41 4 -56 3 -40
-3z"/>
<path d="M1263 3633 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6023 3633 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M1125 3620 c-4 -6 15 -10 49 -10 31 0 56 5 56 10 0 6 -22 10 -49 10
-28 0 -53 -4 -56 -10z"/>
<path d="M6080 3621 c0 -6 -12 -12 -27 -14 -16 -2 -8 -3 16 -1 24 2 63 4 87 4
24 0 44 5 44 10 0 6 -27 10 -60 10 -33 0 -60 -4 -60 -9z"/>
<path d="M1263 3603 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M1321 3596 c2 -2 36 -6 74 -9 54 -4 61 -4 30 3 -40 9 -112 13 -104 6z"/>
<path d="M5900 3590 c-16 -5 -2 -6 35 -3 33 3 62 7 64 9 8 7 -74 2 -99 -6z"/>
<path d="M1495 3570 c17 -4 41 -8 55 -8 22 0 22 1 -5 8 -16 4 -41 8 -55 8 -22
0 -22 -1 5 -8z"/>
<path d="M5780 3570 c-34 -7 -34 -7 -5 -8 17 0 44 4 60 8 27 7 27 8 5 8 -14
-1 -41 -4 -60 -8z"/>
<path d="M1588 3553 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5675 3550 c-27 -7 -27 -8 -5 -8 14 0 36 4 50 8 20 6 21 8 5 8 -11 0
-33 -4 -50 -8z"/>
<path d="M1675 3535 c63 -18 81 -18 35 0 -19 7 -46 14 -60 14 -14 0 -4 -6 25
-14z"/>
<path d="M5578 3523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5510 3510 c-11 -8 -7 -9 15 -4 37 8 45 14 19 14 -10 0 -26 -5 -34
-10z"/>
<path d="M5445 3490 c-13 -5 -14 -9 -5 -9 8 0 24 4 35 9 13 5 14 9 5 9 -8 0
-24 -4 -35 -9z"/>
<path d="M2071 3418 c8 -9 26 -19 39 -23 21 -7 22 -6 9 7 -8 9 -26 19 -39 23
-21 7 -22 6 -9 -7z"/>
<path d="M2150 3380 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M5155 3380 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2197 3359 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5110 3360 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2270 3327 c0 -3 15 -10 32 -16 18 -6 28 -7 23 -2 -12 10 -55 25 -55
18z"/>
<path d="M5008 3314 c-39 -21 -35 -28 5 -8 37 19 44 24 31 24 -5 -1 -21 -8
-36 -16z"/>
<path d="M2354 3286 c11 -9 24 -16 30 -16 12 0 7 5 -24 19 -24 11 -24 11 -6
-3z"/>
<path d="M4950 3280 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4878 3244 c-38 -20 -36 -28 2 -9 17 9 30 18 30 20 0 7 -1 6 -32 -11z"/>
<path d="M2430 3240 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M2488 3211 c12 -10 22 -22 22 -25 0 -3 -128 -6 -285 -6 l-285 0 0
-32 0 -33 115 1 c64 0 118 -3 120 -7 3 -4 6 -170 8 -370 l2 -362 25 -4 c53 -6
52 -13 51 370 -2 263 1 357 10 363 6 4 49 6 95 5 115 -3 154 5 154 33 0 12 4
26 9 32 6 5 -4 17 -27 32 -28 18 -32 18 -14 3z"/>
<path d="M2905 3193 c-48 -10 -114 -39 -163 -71 -32 -21 -59 -32 -69 -28 -13
5 -14 4 -4 -8 9 -11 7 -20 -12 -42 -101 -121 -115 -321 -33 -481 40 -78 107
-134 206 -170 105 -39 254 -38 362 3 24 9 53 17 66 18 13 0 22 7 22 18 0 28
-19 68 -31 68 -7 0 -21 8 -32 18 -19 16 -20 16 -8 -5 16 -30 16 -29 -36 -50
-37 -14 -75 -18 -163 -18 -103 0 -121 3 -171 26 -69 33 -127 89 -160 155 -21
42 -24 63 -24 154 0 97 2 110 30 164 36 72 56 87 83 63 10 -9 22 -17 27 -17 4
0 -4 9 -19 21 -14 11 -26 24 -26 28 0 4 25 23 55 43 105 69 251 74 379 14 30
-14 57 -26 60 -26 3 0 12 13 20 28 15 28 14 29 -22 49 -64 36 -140 53 -231 52
-47 -1 -95 -3 -106 -6z"/>
<path d="M3933 3179 c-124 -48 -172 -189 -100 -298 33 -50 84 -74 223 -105
123 -27 184 -52 184 -75 0 -8 -15 -30 -32 -49 -18 -18 -26 -31 -18 -27 9 3 24
17 34 30 35 46 56 24 56 -58 0 -75 -39 -119 -128 -143 -50 -14 -67 -15 -70 -6
-3 10 -7 10 -18 1 -21 -17 -124 -4 -197 26 -65 26 -71 24 -83 -23 -4 -13 -4
-25 -1 -28 12 -13 152 -54 182 -54 19 0 36 -4 39 -8 2 -4 -11 -39 -31 -77
-119 -232 -209 -503 -300 -910 l-9 -40 -18 80 c-34 154 -75 311 -106 410 -44
134 -91 257 -97 251 -3 -3 6 -34 21 -68 60 -145 134 -411 177 -633 26 -132 23
-133 54 30 51 278 151 593 258 810 27 55 53 110 57 121 7 18 20 23 72 29 133
14 212 57 254 135 27 52 26 162 -3 218 l-23 43 41 44 c23 25 38 45 34 45 -4 0
-24 -18 -44 -41 l-36 -41 -35 20 c-39 23 -106 47 -160 57 -92 16 -162 40 -191
64 -26 22 -32 36 -36 76 -10 123 102 181 275 140 35 -8 73 -22 85 -30 29 -21
34 -19 52 15 15 29 15 30 -14 45 -90 46 -260 58 -348 24z"/>
<path d="M4713 3138 c-51 -34 -90 -64 -88 -66 3 -4 153 96 180 120 25 22 -10
2 -92 -54z"/>
<path d="M1268 3184 c-5 -4 -8 -187 -8 -407 l0 -399 203 4 c232 4 263 12 340
91 90 91 74 239 -34 308 -54 35 -56 40 -33 60 23 19 53 82 60 127 13 85 -45
165 -146 202 -39 14 -370 27 -382 14z m389 -90 c62 -45 77 -126 36 -195 -30
-50 -67 -60 -215 -57 l-133 3 -3 124 c-1 69 0 131 3 139 4 11 32 13 144 10
122 -3 143 -6 168 -24z m37 -344 c96 -49 101 -208 9 -270 -34 -23 -47 -25
-196 -30 -87 -3 -161 -3 -163 -1 -2 2 -4 75 -4 161 0 86 2 159 4 161 2 2 76 2
163 -1 113 -4 166 -9 187 -20z"/>
<path d="M4497 3183 c-4 -3 -7 -55 -7 -114 l0 -108 -50 -47 c-28 -26 -48 -49
-46 -51 2 -2 24 15 47 38 l44 43 6 -154 c6 -164 20 -230 65 -301 57 -91 185
-137 327 -118 91 12 159 48 202 107 66 90 70 114 70 427 l0 280 -35 0 -35 0
-5 -285 c-5 -274 -6 -287 -28 -330 -13 -25 -34 -56 -45 -68 -80 -88 -261 -94
-346 -14 -75 72 -91 132 -91 356 0 159 1 171 21 192 l21 23 -21 -6 c-20 -5
-21 -2 -21 66 l0 71 -33 0 c-19 0 -37 -3 -40 -7z"/>
<path d="M5338 3184 c-5 -4 -8 -187 -8 -407 l0 -399 208 4 c223 4 246 9 311
64 51 42 75 99 75 174 1 55 -4 71 -28 108 -16 23 -45 52 -65 64 -20 12 -38 23
-41 25 -2 2 7 14 21 26 15 12 33 36 43 54 20 40 21 135 1 176 -17 35 -71 77
-125 97 -39 14 -380 27 -392 14z m363 -75 c65 -20 84 -49 84 -130 0 -64 -2
-71 -33 -101 l-32 -33 -150 -3 -150 -3 0 140 0 141 121 0 c67 0 139 -5 160
-11z m39 -345 c18 -4 47 -22 65 -40 80 -79 42 -233 -64 -264 -18 -5 -96 -10
-174 -11 l-142 -1 -3 161 -2 161 144 0 c79 0 158 -3 176 -6z"/>
<path d="M2553 3172 c9 -13 87 -66 91 -61 3 2 -18 19 -46 37 -28 19 -48 29
-45 24z"/>
<path d="M2860 2928 c41 -45 50 -53 50 -45 0 2 -21 23 -47 47 l-48 45 45 -47z"/>
<path d="M2941 2845 c26 -32 109 -111 109 -103 0 7 -27 35 -85 88 -30 28 -40
34 -24 15z"/>
<path d="M3060 2723 c0 -4 12 -21 26 -38 14 -16 44 -56 66 -88 22 -32 42 -56
45 -53 5 5 -22 44 -94 134 -24 30 -43 50 -43 45z"/>
<path d="M4160 2599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4120 2538 c-17 -22 -27 -44 -24 -47 4 -3 9 -1 11 6 2 7 15 28 28 48
34 49 22 44 -15 -7z"/>
<path d="M3280 2399 c0 -4 20 -42 45 -85 51 -90 60 -93 16 -7 -30 58 -60 104
-61 92z"/>
<path d="M3372 2213 c38 -81 62 -123 66 -119 3 3 0 11 -5 18 -5 7 -17 34 -27
61 -10 26 -22 47 -28 47 -5 0 -8 -3 -6 -7z"/>
<path d="M1552 2201 c-8 -5 -12 -23 -10 -47 3 -34 6 -39 31 -42 28 -3 31 0 41
56 4 22 2 32 -7 32 -9 0 -12 -11 -9 -34 2 -19 -1 -37 -8 -41 -18 -11 -24 2
-22 45 3 44 3 44 -16 31z"/>
<path d="M2252 2197 c-13 -16 -31 -84 -24 -91 11 -12 24 -6 19 9 -10 24 10 27
31 4 26 -27 33 -13 20 38 -11 44 -30 60 -46 40z m28 -38 c0 -5 -7 -9 -15 -9
-15 0 -20 12 -9 23 8 8 24 -1 24 -14z"/>
<path d="M2767 2203 c-3 -5 -5 -27 -4 -50 0 -33 4 -43 16 -43 13 0 16 8 13 42
-4 34 -2 40 11 35 9 -3 19 -2 22 2 2 5 -5 11 -18 15 -29 8 -35 7 -40 -1z"/>
<path d="M2916 2201 c-4 -5 -13 -28 -21 -50 -15 -44 -4 -56 21 -23 12 16 13
16 21 -5 6 -16 12 -20 22 -14 11 7 11 15 0 52 -14 42 -32 59 -43 40z m21 -47
c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z"/>
<path d="M3340 2154 c0 -40 4 -55 13 -52 19 7 21 94 2 102 -12 4 -15 -5 -15
-50z"/>
<path d="M3461 2193 c-31 -21 -28 -71 4 -79 47 -12 73 13 61 60 -7 29 -38 38
-65 19z m37 -41 c2 -24 -1 -32 -12 -32 -12 0 -16 10 -16 36 0 45 24 42 28 -4z"/>
<path d="M3677 2203 c-4 -3 -7 -15 -7 -25 0 -24 -17 -23 -30 2 -20 37 -40 24
-40 -25 0 -31 4 -45 14 -45 9 0 12 9 9 28 l-4 27 23 -27 c35 -42 48 -36 48 22
0 51 -1 55 -13 43z"/>
<path d="M4007 2203 c-13 -12 -8 -93 6 -93 8 0 12 15 12 45 -1 45 -6 60 -18
48z"/>
<path d="M5750 2160 c0 -33 4 -50 13 -50 17 0 17 88 0 94 -10 4 -13 -8 -13
-44z"/>
<path d="M6016 2200 c-12 -37 -12 -90 1 -90 8 0 13 12 13 28 l0 27 24 -30 c13
-16 26 -28 29 -25 2 3 7 24 10 48 4 31 2 42 -8 42 -8 0 -11 -8 -8 -20 7 -28
-7 -25 -33 8 -17 21 -24 24 -28 12z"/>
<path d="M1255 2169 c9 -17 14 -37 11 -45 -3 -9 1 -14 13 -14 13 0 20 9 23 28
3 15 9 35 13 45 4 9 3 17 -3 17 -5 0 -13 -10 -17 -22 l-8 -23 -4 23 c-3 14
-12 22 -24 22 -19 0 -19 -1 -4 -31z"/>
<path d="M1389 2179 c-17 -33 4 -71 38 -67 33 4 48 27 39 61 -5 22 -12 27 -36
27 -20 0 -33 -7 -41 -21z m51 -24 c0 -24 -5 -35 -15 -35 -15 0 -22 50 -8 63
15 15 23 5 23 -28z"/>
<path d="M1694 2155 c-1 -32 2 -45 12 -45 10 0 14 13 14 40 0 29 4 40 15 40
17 0 20 -25 5 -35 -7 -4 -7 -13 0 -26 17 -30 25 -22 25 24 0 41 -1 42 -35 45
l-35 3 -1 -46z"/>
<path d="M2086 2185 c-3 -9 4 -21 19 -31 32 -21 32 -38 0 -30 -14 4 -25 2 -25
-4 0 -15 56 -12 69 4 10 12 7 18 -13 31 -34 22 -34 39 0 31 17 -5 23 -3 19 4
-10 16 -62 12 -69 -5z"/>
<path d="M2371 2183 c0 -11 3 -13 6 -5 11 28 25 10 21 -28 -3 -31 0 -40 12
-40 12 0 15 9 12 42 -4 35 -2 40 12 35 9 -4 16 -2 16 3 0 6 -18 10 -40 10 -32
0 -40 -4 -39 -17z"/>
<path d="M2520 2155 c0 -29 4 -45 13 -45 8 0 12 16 12 45 0 29 -4 45 -12 45
-9 0 -13 -16 -13 -45z"/>
<path d="M2626 2184 c-4 -11 1 -22 14 -31 26 -18 26 -36 0 -28 -12 4 -20 2
-20 -5 0 -7 13 -10 32 -8 38 4 51 33 19 43 -12 3 -21 13 -21 21 0 11 6 13 20
9 11 -4 20 -2 20 4 0 18 -57 13 -64 -5z"/>
<path d="M3051 2176 c-19 -34 0 -66 39 -66 21 0 30 5 30 16 0 13 -3 14 -15 4
-19 -16 -35 -4 -35 25 0 29 16 41 35 25 12 -10 15 -9 15 4 0 27 -54 21 -69 -8z"/>
<path d="M3190 2183 c0 -15 2 -16 10 -3 8 12 10 12 16 -3 3 -9 3 -28 0 -42 -5
-20 -3 -25 12 -25 15 0 17 7 14 42 -4 35 -2 40 12 35 9 -4 16 -2 16 3 0 6 -18
10 -40 10 -31 0 -40 -4 -40 -17z"/>
<path d="M4103 2183 c-4 -11 1 -22 15 -31 28 -18 29 -35 2 -27 -12 4 -20 2
-20 -5 0 -7 13 -10 32 -8 41 4 48 30 13 46 -29 13 -24 36 6 27 11 -4 19 -2 19
4 0 18 -60 13 -67 -6z"/>
<path d="M4481 2178 c-17 -47 7 -76 53 -64 52 13 40 86 -15 86 -21 0 -32 -6
-38 -22z m55 -14 c7 -27 -7 -50 -25 -40 -12 8 -15 49 -4 59 13 13 23 7 29 -19z"/>
<path d="M4634 2168 c2 -18 4 -39 5 -45 1 -14 29 -17 55 -7 18 7 23 84 6 84
-5 0 -10 -18 -10 -40 0 -29 -4 -40 -15 -40 -11 0 -15 11 -15 40 0 28 -4 40
-14 40 -10 0 -13 -10 -12 -32z"/>
<path d="M4788 2154 c-3 -48 9 -72 19 -35 6 25 18 27 27 6 3 -8 13 -15 22 -15
13 0 14 3 5 14 -6 7 -8 27 -5 45 6 30 5 31 -29 31 -34 0 -35 -1 -39 -46z m50
19 c-5 -25 -28 -28 -28 -4 0 12 6 21 16 21 9 0 14 -7 12 -17z"/>
<path d="M5175 2156 c0 -30 4 -46 13 -46 6 0 12 6 12 13 0 7 10 17 23 22 12 6
22 19 22 30 0 16 -8 21 -35 23 l-36 3 1 -45z m53 17 c-5 -25 -28 -28 -28 -4 0
12 6 21 16 21 9 0 14 -7 12 -17z"/>
<path d="M5322 2173 c-5 -16 -13 -36 -17 -45 -5 -13 -2 -18 10 -18 9 0 14 4
10 9 -10 17 18 24 30 8 7 -10 19 -17 28 -17 14 0 14 3 1 28 -7 15 -14 35 -14
45 0 28 -37 21 -48 -10z m32 -14 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5
10 7 15 3 5 -3 7 -10 3 -15z"/>
<path d="M5474 2185 c-7 -18 3 -35 22 -35 8 0 14 -7 14 -16 0 -11 -6 -14 -20
-9 -12 4 -20 2 -20 -5 0 -7 13 -10 32 -8 38 4 51 33 19 43 -12 3 -21 13 -21
21 0 11 6 13 20 9 11 -4 20 -2 20 4 0 17 -60 13 -66 -4z"/>
<path d="M5611 2186 c-10 -12 -7 -18 13 -31 33 -22 34 -39 1 -31 -14 4 -25 2
-25 -4 0 -5 11 -10 24 -10 57 0 75 28 31 48 -14 6 -25 17 -25 23 0 7 9 9 25 5
14 -4 25 -2 25 4 0 15 -56 12 -69 -4z"/>
<path d="M5854 2173 c-9 -34 6 -57 39 -61 20 -3 30 3 38 21 16 36 -3 67 -41
67 -24 0 -31 -5 -36 -27z m54 -21 c2 -24 -1 -32 -12 -32 -17 0 -32 42 -21 60
12 20 30 4 33 -28z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
